import { Injectable } from '@angular/core';
import { APP } from '../constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { from, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private _http: HttpClient,
    private router: Router,
  ) { }

  setHeader() {
    return new HttpHeaders().set('Content-Type', 'application/json');
  }

  public isAuthenticated(): any {
    const token = localStorage.getItem('overview-userLogin');
    window['id_user'] = JSON.parse(token)?.id;
    return token != null;
  }

  public getInfo(): any {
    const info = localStorage.getItem('overview-userLogin');
    return JSON.parse(info);
  }

  login(data: any, callback) {
    let headers = this.setHeader();
    return this._http.post(`${APP.API_URL}/auth/login`, data, { headers: headers }).subscribe(
      (data) => callback(data),
      (error) => callback(error.error),
    );
  }

  public logout() {
    localStorage.removeItem('overview-userLogin');
    this.router.navigate(['/login']);
  }

  
  getConexiones(): Observable<any> {
    let headers = this.setHeader();

    return from(this._http.get<any>(`${APP.API_URL}/admin/conexiones`,{ headers: headers })).pipe(
      map((data: any) => {
        return data
      })
    )
  }

}
