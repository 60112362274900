import packageInfo from '../../package.json';

export const environment = {
  appVersion: packageInfo.version,
  // API_URL: 'https://apiformiodev.mobilsaas.com',
  API_URL: 'https://api.mobilsaas.com',
  // API_URL: 'http://localhost:3000',
  APP_URL: 'http://localhost:4200',
  SUBDOMINIO: 'demosaas',
  APP: 'formio',
  key:'SecretKeyMobilsoft',
  production: false
};