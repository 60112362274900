import { HttpClient } from '@angular/common/http'
import { EventEmitter, Injectable, Output, signal } from '@angular/core'
import { EventEmitDetailInterface } from '@interfaces/EventEmitDetail.interface'
import { IFormBuilder, IFormBuilderFields } from '@interfaces/form-builder.interface'
import { Observable, firstValueFrom, from, map } from 'rxjs'
import { APP } from 'src/app/constants'

@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {
  apiUrl: string = APP.API_URL;
  @Output() EmitAssignData = new EventEmitter<any>();
  @Output() EmitConstructionData = new EventEmitter<any>();
  @Output() EmitConstruction = new EventEmitter<{ type: "event" | "assin", name: "NewForm", data?: any }>();
  @Output() EmitChangeInput = new EventEmitter<{ field: string, value: any }>();
  @Output() EventEmitDetail = new EventEmitter<EventEmitDetailInterface>();
  constructor(private http: HttpClient) { }

  async searchForm(id: number): Promise<any> {
    try {
      const apiUrl = `${this.apiUrl}/admin/forms/findOneJoin/${id}`
      const data = await this.http.get(apiUrl).toPromise()
      return data
    } catch (error) {
      throw new Error('Error recibido')
    }
  }

  consecutive(form: any): any {
    const apiUrl = `${this.apiUrl}/admin/forms/builder`
    return from(this.http.post(apiUrl, form)).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  listBuilder(form: any): any {
    const apiUrl = `${this.apiUrl}/admin/forms/builder`
    return from(this.http.post(apiUrl, form)).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  fetchData(form: any): any {
    const apiUrl = `${this.apiUrl}/admin/forms/builder`
    return from(this.http.post(apiUrl, form)).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  createItem(form: any): Observable<any> {
    return from(
      this.http.post<any>(`${this.apiUrl}/admin/forms/builder`, form)
    ).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  updateItem(form: any): Observable<any> {
    return from(
      this.http.post<any>(`${this.apiUrl}/admin/forms/builder`, form)
    ).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  deleteForm(id: any): Observable<void> {
    const url = `${this.apiUrl}/admin/forms/builder/${id}`
    // return this.http.post<void>(url, form);
    return this.http.delete<void>(url)

  }

  // -----> Design

  fetchDataForms(): Observable<any> {
    return from(this.http.get<any>(`${this.apiUrl}/admin/forms`)).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  fetchTablas(): Observable<any> {
    return from(this.http.get<any>(`${this.apiUrl}/admin/forms/tables`)).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  fetchColumns(nombre: string): Observable<any> {
    return from(this.http.get<any>(`${this.apiUrl}/admin/forms/columns/${nombre}`)).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  createForm(item: any): Observable<any> {
    return from(this.http.post<any>(`${this.apiUrl}/admin/forms`, item)).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  updateForm(item: any): Observable<any> {
    return from(
      this.http.patch<any>(`${this.apiUrl}/admin/forms/${item.id}`, item)
    ).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  async fetchSelect(id: number): Promise<any> {
    try {
      const apiUrl = `${this.apiUrl}/admin/forms/fetch-select/${id}`
      return await firstValueFrom(this.http.get<any>(apiUrl)).then();
    } catch (error) {
      throw new Error('Error recibido')
    }
  }

  fetchDataTable(json: any): Observable<any> {
    return from(this.http.post(`${this.apiUrl}/admin/forms/fetch-table`, json)).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  GenerateForm(json: any): Observable<any> {
    return from(this.http.post<any>(`${this.apiUrl}/admin/forms/generateForm`, json)).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  GetComponents(type: any): any {
    const apiUrl = `${this.apiUrl}/admin/forms/Components`
    return from(this.http.post(apiUrl, type)).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  // -----> Signal
  signalHideModal = signal<boolean>(false)
  formData = signal<IFormBuilderFields | undefined>(undefined)
  formBuilder = signal<IFormBuilder | undefined>(undefined)
  gridBuilder = signal<string | null>(null)
  gridBuilderDetail = signal<string | null>(null)
  signalEditFormBuilder = signal<string | null>(null)
  signalFieldDetail = signal<IFormBuilderFields | undefined>(undefined)
  // signalRowsDetail = signal<IRowsDetail | undefined>(undefined);
  SignalCamposForm = signal<IFormBuilderFields[] | []>([])
}
