<app-navigation
  class="coded-navbar pc-sidebar"
  [ngClass]="{
    'navbar-collapsed': navCollapsed,
    'mob-open': navCollapsedMob
  }"
  (NavCollapsedMob)="navMobClick()"
></app-navigation>
<app-nav-bar (NavCollapse)="this.navCollapsed = !this.navCollapsed" (NavCollapsedMob)="navMobClick()"></app-nav-bar>
<div class="pc-container">
  <div class="coded-wrapper">
    <div class="coded-content">
      <div class="coded-inner-content">
        <app-breadcrumbs></app-breadcrumbs>
        <div class="main-body">
          <div class="page-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="pc-footer">
  <div class="footer-wrapper container-fluid">
    <div class="row">
      <div class="col my-1">
        <p class="m-0"> Copyright &copy; <a href="https://mobilsoft.co/" target="_blank">Mobilsoft</a> </p>
      </div>
      <div class="col-auto my-1">
        <ul class="list-inline footer-link mb-0">
          <li class="list-inline-item"><a href="https://mobilsoft.co/" target="_blank">Inicio</a></li>
          <li class="list-inline-item"><a href="https://mobilsoft.co/" target="_blank">Contacto</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
<!--<app-configuration></app-configuration>-->
