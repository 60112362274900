// angular import
import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { LoginClass } from './login.class';
import { LoginService } from '../../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { RouterModule, Router } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';


import * as CryptoJS from 'crypto-js';
import { APP } from '../../constants';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [RouterModule, FormsModule, CommonModule, NgSelectModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLoading: boolean = false;
  formData: LoginClass;
  ListAmbientes: any[] = [];
  AmbienteSelected: any;

  constructor(
    private LoginService: LoginService,
    private toastr: ToastrService,
    private router: Router
  ) { 
    this.formData = new LoginClass();
  }

  ngOnInit(): void {
    localStorage.setItem('ambiente', null);

    if (localStorage.getItem('overview-userLogin') !== null) this.router.navigateByUrl('/home');
    this.LoginService.getConexiones().subscribe(res => {
      this.ListAmbientes = res;
    })
  }

  login(data) {
    this.isLoading = true;
    this.LoginService.login(this.formData, data => {
      if (data?.result) {
        localStorage.setItem('overview-userLogin', JSON.stringify(data.result[0]));
        this.router.navigateByUrl('/home');
      } else {
        this.toastr.error('Usuario o Contraseña Incorrectos.');
        this.isLoading = false;
      }
    });
  }

  changeAmbiente(event: any) {
    this.AmbienteSelected = event;
    localStorage.setItem('ambiente', this.encryptData(event)
  );
  }

  // Función para encriptar los datos
  encryptData(data: any): string {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), APP.key).toString();
    return ciphertext;
  }
   // Función para encriptar los datos
   DesencryptData(data: any): string {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), APP.key).toString();
    return ciphertext;
  }
  decryptData(encryptedData: string): Promise<any> {
		const bytes = CryptoJS.AES.decrypt(encryptedData, APP.key);
		const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		return decryptedData;
	}
}