import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'empleados-cell-component',
  template: `
    <div>
        <div class="row mt-1">
            <div class="col-sm-8">
                <label><span class="badge badge-pill badge-light mb-1 bg-transparent">Nombre: </span> <br> <span style="padding-left: 6px">{{ nombre }}</span></label>
            </div>
            <div class="col-sm-4">
                <label><span class="badge badge-pill badge-light mb-1 bg-transparent">Sede: </span> <br> <span style="padding-left: 6px">{{ sede }}</span></label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 pr-0">
                <label class="center-div-y"><span class="badge badge-pill badge-light bg-transparent" style="padding-right: 13px;">Cod: </span> {{ codigo }}</label>
            </div>
            <div class="col-sm-8">
                <label class="w-100 text-center badge badge-pill badge-light" style="background: #1ebee2; color: white;"><span class="badge badge-pill badge-light mr-2 pull-left" style="padding-top: 4px; margin-top: 1px;">Cedula: </span> <span style="font-size: 14px;">{{ cedula }}</span></label>
            </div>
        </div>
    </div>
  `,
})
export class empleadosCell implements ICellRendererAngularComp {
  public nombre: string;
  public codigo: string;
  public cedula: string;
  public sede: string;

  // gets called once before the renderer is used
  agInit(params: any): void {
    this.nombre = params.data.nombre.substr(0, 26);
    this.codigo = params.data.codigo;
    this.cedula = params.data.documento;
    this.sede = params.data.sede;
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: any): boolean {
    this.nombre = params.data.nombre.substr(0, 26);
    this.codigo = params.data.codigo;
    this.cedula = params.data.documento;
    this.sede = params.data.sede;
    return true;
  }


}