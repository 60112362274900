import { environment } from '../environments/environment';

export const APP: any = {
    API_URL: environment.API_URL,
    APP_URL: environment.APP_URL,
    SUBDOMINIO: environment.SUBDOMINIO,
    APP: environment.APP,
    production: environment.production,
    key: environment.key
}
