import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'checkbox-renderer',
  template: `
    <input 
      type="checkbox" 
      (click)="checkedHandler($event)"
      [checked]="params.value"
      style="font-size: 36px !important; min-width: 34px; min-height: 29px; margin-top: 5px;" />
`,
})
export class CheckboxComponent implements ICellRendererAngularComp, OnDestroy {
  public params: any;
  refresh(params: any): boolean {
    return true;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }
  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
  }

  agInit(params: any): void {
    this.params = params;
  }

  checkedHandler(event) {
    const checked = event.target.checked;
    if (!checked) {
      this.params.node.setDataValue('valor_pago', 0);
    } else {
      if (this.params.data.valor_pago === 0) {
        this.params.node.setDataValue('valor_pago', this.params.data.saldo);
      }
    }
    const colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }
}
