import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { LoginComponent } from './views/login/login.component';
import { VerifyRoutingService } from './services/validate.routing';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [VerifyRoutingService],
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadComponent: () => import('./views/home/home.component')
      },
      {
        path: 'config',
        loadComponent: () => import('./views/config/config.component')
      },
      {
        path: 'herramientas',
        loadChildren: () => import('./views/herramientas/herramientas.module').then((m) => m.HerramientasModule)
      },
      {
        path: 'compras',
        loadChildren: () => import('./views/compras/compras.module').then((m) => m.ComprasModule)
      },
      {
        path: 'form/:id',
        loadComponent: () => import('./views/herramientas/render/render.component'),
        // pathMatch: 'full'
      }
    ]
  },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
