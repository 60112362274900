import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'btn-cell-update-item',
  template: `<button
  (click)='btnDelete()'
  class='btn-delete-grid'>
  <i class="fa fa-trash"></i>
</button>
  `,
})
export class BtnDefaultCellDelete implements ICellRendererAngularComp, OnDestroy {
  params: any;

  refresh(params: any): boolean {
    throw new Error('Method not implemented.');
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }

  agInit(params: any): void {
    this.params = params;
  }

  btnDelete() {
    this.params.delete(this.params.node.data);
  }

  ngOnDestroy() {
  }
}
