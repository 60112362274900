// Angular import
import { Component } from '@angular/core';
import { LoginService } from '../../../../../services/login.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss']
})
export class NavRightComponent {

  userLoginInfo: any = {};

  constructor(
    private LoginService: LoginService
  ) {
    this.userLoginInfo = this.LoginService.getInfo();
  }

  logout() {
    this.LoginService.logout();
  }

}
