import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';

import { BtnDefaultCellEdit } from './btn-default-cell-edit.component';
import { FormsModule } from '@angular/forms';
import { BtnDefaultCellDelete } from './btn-delete.component';
import { btnEditardelete } from './btns-editar-delete.component';
import { btnEditardelete2 } from './btns-delete-edit-2.component';
import { empleadosCell } from './empleado-column-grid.component';
import { CheckboxComponent } from './checkbox.component';
import { ButtonsDefaultComponent } from './buttons-default.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    AgGridModule,
    BtnDefaultCellEdit,
    BtnDefaultCellDelete,
    ButtonsDefaultComponent,
    btnEditardelete,
    btnEditardelete2,
    empleadosCell,
    CheckboxComponent
  ],
  declarations: [BtnDefaultCellEdit, BtnDefaultCellDelete,ButtonsDefaultComponent, btnEditardelete, btnEditardelete2, empleadosCell, CheckboxComponent],
})
export class GridModule {}
