import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'btn-cell-update-item',
  template: `
    <button
      (click)='btnClickedHandler()'
      style="padding: 0px 5px; font-size: 12px;"
      class='btn btn-primary btn-sm w-100'>
      <i class="fa fa-edit"></i> Editar
    </button>
  `,
})
export class BtnDefaultCellEdit implements ICellRendererAngularComp, OnDestroy {
  params: any;

  refresh(params: any): boolean {
    throw new Error('Method not implemented.');
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }

  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler() {
    this.params.update(this.params.node.data);
  }

  ngOnDestroy() {
  }
}
