import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { IFormBuilder } from '@interfaces/form-builder.interface';
import { from, map } from 'rxjs';
import { APP } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  apiUrl: string = APP.API_URL
  constructor(private http: HttpClient) { }

  fetchData(): any {
    const apiUrl = `${this.apiUrl}/admin/forms/sidebar`
    return from(this.http.get(apiUrl)).pipe(
      map((data: any) => {
        return data
      })
    )
  }

  signalNewForm = signal<IFormBuilder | undefined>(undefined)

}
