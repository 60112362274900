import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoginService } from '@services/login.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private LoginService: LoginService) {}

  ambiente = localStorage.getItem('ambiente');

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(localStorage.getItem('ambiente')){

      let requests = request.clone({ headers: request.headers.set('ambiente', localStorage.getItem('ambiente')) });
      return next.handle(requests).pipe(
        catchError((error: HttpErrorResponse) => {
          // Verifica si el mensaje de error contiene "Unauthorized"
          if (error.error?.message === 'Unauthorized') {
            // Redirige al login o muestra una notificación
            this.LoginService.logout();
            console.error('User is unauthorized');
          }
          return throwError(() => error);
        })
      );
    }else{
      this.LoginService.logout();

    }

  }
  
}
