<ng-scrollbar style="height: calc(100vh - 86px)" visibility="hover">
  <p class="name_database" *ngIf="ambiente?.database_name">{{ambiente.database_name}}</p>
  <div class="navbar-content ng-scroll-viewport">
    <ul class="nav coded-inner-navbar" (clickOutside)="fireOutClick()" (mouseleave)="navMob()">
      @for (item of navigation; track item) {
        @if (item.type == 'group') {
          <app-nav-group [item]="item"></app-nav-group>
        }
        @if (item.type == 'collapse') {
          <app-nav-collapse [item]="item"></app-nav-collapse>
        }
        @if (item.type == 'item') {
          <app-nav-item [item]="item"></app-nav-item>
        }
      }

      <!-- @for (item of NavigationItems; track item) {
        @if (item.type == 'group') {
          <app-nav-group [item]="item"></app-nav-group>
        }
        @if (item.type == 'collapse') {
          <app-nav-collapse [item]="item"></app-nav-collapse>
        }
        @if (item.type == 'item') {
          <app-nav-item [item]="item"></app-nav-item>
        }
      } -->

      
    </ul>
  </div>
</ng-scrollbar>
