import { Component, HostListener, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {
  public innerHeight: any;
  
  // public props
  @Input() cardTitle: string;
  @Input() showHeader: boolean = true;
  @Input() heightAutoFill: boolean = true;

  ngOnInit() {
    this.innerHeight = window.innerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerHeight = window.innerHeight;
  }

  calculateHeight(){
    return (this.heightAutoFill) ? (this.innerHeight - 255 + "px") : "auto";
  }

}
