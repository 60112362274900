import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-buttons-default',
  template: `
    <button type="button" class="btn btn-xs btn-icon btn-info" (click)="editRow()">
      <i class="fas fa-edit"></i>
    </button>
    <button
      type="button"
      class="btn btn-xs btn-icon btn-danger"
      style="margin-left:5px;"
      (click)="deleteRow()"
    >
      <i class="fas fa-remove"></i>
    </button>
  `,
})
export class ButtonsDefaultComponent implements ICellRendererAngularComp {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public rowValue!: any
  public params!: any

  agInit(params: ICellRendererParams): void {
    this.params = params
  }

  editRow(): any {
     this.params.clicked({data: this.params.node.data, action: 'edit'})
  }

  deleteRow(): any {
     this.params.clicked({ data: this.params.node.data, action: 'del' })
  }

  refresh(): boolean {
    return false
  }
}
