<div class="auth-main">
  <div class="auth-wrapper v3">
    <div class="auth-form">
      <div class="card my-5">
        <div class="card-body">
          <a href="#" class="d-flex justify-content-center">
            <img src="assets/images/logo-dark.svg" />
          </a>
          <div class="row">
            <div class="d-flex justify-content-center">
              <div class="auth-header">
                <h2 class="text-secondary mt-5"><b>Hola, Bienvenido de vuelta</b></h2>
                <p class="fs-4 mt-2">Ingresa tus credenciales para continuar</p>
              </div>
            </div>
          </div>
          <form #loginForm="ngForm" (ngSubmit)="!loginForm.valid || login(loginForm)" novalidate onsubmit="false">
            <div class="form-group mb-3">
              <label for="ambiente">Ambiente:</label>
              <ng-select class="zoom-select" [items]="ListAmbientes" bindLabel="name" bindValue="id"
                placeholder='Seleccione un Ambiente' name="ambiente" [(ngModel)]="formData.id_ambiente"
                (change)="changeAmbiente($event)" #ambiente>
              </ng-select>

            </div>
            <div class="form-floating mb-3">
              <input type="email" class="form-control" autocomplete="on" [(ngModel)]="formData.email" name="email"
                #email="ngModel" required aria-describedby="email">
              <label for="floatingInput">Email</label>
            </div>
            <div class="form-floating mb-3">
              <input type="password" class="form-control" autocomplete="off" (keydown.enter)="login(loginForm)"
                [(ngModel)]="formData.password" name="pass" #pass="ngModel" required aria-describedby="pass">
              <label for="password">Contraseña</label>
            </div>
            <div class="d-flex mt-1 justify-content-between">
              <h5 class="text-secondary">
                <a href="javascript:">Recuperar Contraseña?</a>
              </h5>
            </div>
            <div class="d-grid mt-4">
              <button type="button" class="btn btn-secondary" [disabled]="!loginForm.valid || formData.id_ambiente == null "
                (click)="login(loginForm)">
                <span *ngIf="!isLoading"><i class="fa fa-unlock-alt"></i> Entrar</span>
                <span *ngIf="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>