import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUser } from '@interfaces/user.interface';

@Injectable({
	providedIn: 'root'
})
export class AuthorizationInterceptor implements HttpInterceptor {
	constructor() { }

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const data: any = localStorage.getItem('overview-userLogin');
		const user: IUser | undefined = data ? JSON.parse(data) : undefined;

		if (user) {
			const clonedReq = req.clone({
				setHeaders: { 'Authorization': `Bearer ${user.token}` }
			});

			return next.handle(clonedReq);
		} else {
			// Si no hay usuario, continuar con la solicitud original
			return next.handle(req);
		}
	}
}