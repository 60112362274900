// Angular import
import { Component, effect, EventEmitter, OnInit, Output } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';

// project import
import { NavigationItem } from '../navigation';
import Swal from 'sweetalert2';
import { MainService } from '@services/main.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { APP } from '../../../../../constants';
import * as CryptoJS from 'crypto-js';
import { FormBuilderService } from '@services/form-builder.service';

@Component({
  selector: 'app-nav-content',
  templateUrl: './nav-content.component.html',
  styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent implements OnInit {
  // public props
  @Output() NavCollapsedMob: EventEmitter<any> = new EventEmitter();

  navigation: any;
  windowWidth = window.innerWidth;
  NavigationItems: NavigationItem[] = [];
  private observables: Subscription[] = [];

  private sidebar = new BehaviorSubject<NavigationItem[]>([]);
  sidebar$ = this.sidebar.asObservable();
  ambiente: any;

  // Constructor
  constructor(
    public nav: NavigationItem,
    private location: Location,
    private locationStrategy: LocationStrategy,
    private serviceMain: MainService,
    private serviceBuilder: FormBuilderService,
  ) {
    this.navigation = this.nav.get();
    effect(() => {
    const newForm = this.serviceMain.signalNewForm()
    if (!newForm) this.listSidebar()
    })
  }

  listSidebar(): void {
    this.observables.push(
      this.serviceMain.fetchData().subscribe(
        (data: any) => {

          this.navigation.forEach(menu => {
            if (menu.id == 'operaciones') {
              menu.children = [...menu.children, ...data];
            }
          });
          // this.NavigationItems=data;
          // this.sidebar.next(data);
        },
        () => {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'upppss!',
            text: 'Se presento un error cargando su configuracion',
            showConfirmButton: false,
            timer: 2500
          })
        }
      )
    )
  }
  // Life cycle events
  ngOnInit() {
    // this.observables.push(
    //   this.serviceBuilder.EmitConstruction.subscribe(evt => {
    //     if (evt.type == 'event' && evt.name == 'NewForm') this.listSidebar()
    //   })
    // )
    this.ambiente = this.decryptData(localStorage.getItem('ambiente'))
    if (this.windowWidth < 1025) {
      (document.querySelector('.coded-navbar') as HTMLDivElement).classList.add('menupos-static');
    }
  }

  fireOutClick() {
    let current_url = this.location.path();
    const baseHref = this.locationStrategy.getBaseHref();
    if (baseHref) {
      current_url = baseHref + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent?.parentElement?.parentElement;
      const last_parent = up_parent?.parentElement;
      if (parent?.classList.contains('coded-hasmenu')) {
        parent.classList.add('coded-trigger');
        parent.classList.add('active');
      } else if (up_parent?.classList.contains('coded-hasmenu')) {
        up_parent.classList.add('coded-trigger');
        up_parent.classList.add('active');
      } else if (last_parent?.classList.contains('coded-hasmenu')) {
        last_parent.classList.add('coded-trigger');
        last_parent.classList.add('active');
      }
    }
  }

  navMob() {
    if (this.windowWidth < 1025 && document.querySelector('app-navigation.coded-navbar').classList.contains('mob-open')) {
      this.NavCollapsedMob.emit();
    }
  }

  decryptData(encryptedData: string): Promise<any> {
    const bytes = CryptoJS.AES.decrypt(encryptedData, APP.key);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }


}
